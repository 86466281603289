<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="SendIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New Message Template') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Active')" label-for="messageTemplate-company">
              <b-checkbox switch v-model="activeEvent">{{ msg('Active') }}</b-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">

            <b-form-group :label="msg('Alias')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('Alias')" rules="required">

                <b-form-input id="realEstate-sell-price" v-model="messageTemplate.alias"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Event')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('Event')" rules="required">

                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="messageTemplate.messageEvent"
                    :options="allEvents"
                    class="w-100"
                    label="text"
                    :reduce="(branch) => branch.value"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Duration in minutes')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('Duration')" rules="required">

                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="minuteOptions"
                    v-model="messageTemplate.minutes"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Channel')" label-for="messageTemplate-company">
              <validation-provider #default="{ errors }" :name="msg('Channel')" rules="required">

                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="messageTemplate.messageChannel"
                    :options="allChannels"
                    class="w-100"
                    label="text"
                    :reduce="(branch) => branch.value"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>




          <!--          <b-col cols="12" md="6" lg="3">
                      <b-form-group :label="msg('Building')" label-for="product-branch">
                        <v-select
                            placeholder="Building"
                            :dir="isRTL ? 'rtl' : 'ltr'"
                            v-model="messageTemplate.buildingId"
                            :options="buildings"
                            class="w-100"
                            label="address"
                            :reduce="(building) => building.id"
                        />
                      </b-form-group>
                    </b-col>-->

          <b-col cols="12" md="9" lg="9">

            <b-form-group :label="msg('Real estate')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Properties')" rules="required">

                <real-estate-picker v-model="messageTemplate.propertyIds" multiple/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


        </b-row>

        <b-row class="mt-1">

          <b-col v-if="messageTemplate.messageChannel!=null && messageTemplate.messageChannel==='EMAIL'" cols="12"
                 md="6" lg="6">

            <b-form-group :label="msg('Subject (relevant for Email event only  - default \'Treasure Home\')')"
                          label-for="messageTemplate-company">

              <b-form-input id="realEstate-sell-price" v-model="messageTemplate.subject"
              />

            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2 mt-1">
            <b-form-group >
              <h5 style="color: dodgerblue">Placeholder Options</h5>
              <div class="text-center mb-1">
                <b-button size="sm" v-for="tag in textTags" v-bind:key="tag.value"
                          pill variant="outline-secondary" class="mr-1" @click="()=>insertPlaceholder(tag.value)">
                  {{ tag.label }}
                </b-button>
              </div>
              <validation-provider #default="{ errors }" :name="msg('Message body')">
                <quill-editor
                    ref="quillEditor"
                    id="realEstate-description"
                    spellcheck="false"
                    v-bind:value="messageTemplate.body"
                    @change="onEditorChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";

const messageTemplateFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})
const buildingFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})

export default {
  name: 'messageTemplatePanel',
  components: {RealEstatePicker},
  data() {
    return {
      loaded: false,

      buildingFilter: buildingFilter(),
      activeEvent: true,
      templateList: [],
      messageTemplateFilter: messageTemplateFilter(),
      messageTemplates: [],
      messageTemplateHostaway: null,
      error: null,
      messageTemplateId: 0,
      buildings: [],
      minuteOptions: [
        {label: '30 minutes', value: 30},
        {label: '1 Hour', value: 60},
        {label: '1.5 Hour', value: 90},
        {label: '2 Hours', value: 120},
        {label: '2.5 Hours', value: 150},
        {label: '3 Hours', value: 180},
        {label: '3.5 Hours', value: 210},
        {label: '4 Hours', value: 240},
        {label: '4.5 Hours', value: 270},
        {label: '5 Hours', value: 300},
      ],
      messageTemplate: {
        company: '',
      },
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },

  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('messageTemplate', ['fileTags', 'allChannelsList', 'allEventsList']),

    textTags() {
      return [
        {value: "#firstname#", label: "FirstName"},
        {value: "#lastname#", label: "Lastname"},
        {value: "#fullAddressApartment#", label: "FullAddressApartment"},
        {value: "#wifiUserName#", label: "WifiUsername"},
        {value: "#wifiPassword#", label: "WifiPassword"},
        {value: "#keyBoxCode#", label: "KeyBoxCode"},
        {value: "#ownerMail#", label: "OwnerMail"},
        {value: "#ownerPhone#", label: "OwnerPhone"},
        {value: "#ownerCompany#", label: "OwnerCompany"},

      ]
    },
    allChannels() {
      return Object.values(this.allChannelsList)
    },
    allEvents() {
      return Object.values(this.allEventsList)
    },
    allTemplates() {
      return this.templateList
          .map(bank => ({
            id: bank.id,
            name: (bank.name)
          }))
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatus() {
      return this.allStatuse.map(status => ({
        value: status.value,
        text: status.text.toUpperCase()
      }))
    },
  },
  created() {
    let $this = this;

    this.getAllBuildings({
      page: 1,
      amount: 9999,
      filter: $this.buildingFilter,
      sortcolumn: 'updatedDate',
      sortby: 'desc',
    }).then($this.onBuildingsRetrieve, (error) => $this.error = error)


    this.onLoad()
    this.$root.$on('crm::submitMessageTemplate', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitMessageTemplate', this.listeners.submit)
  },
  methods: {
    ...mapActions('messageTemplate', ['getAllMessageTemplates']),
    ...mapActions('building', ['getAllBuildings']),

    ...mapActions('messageTemplate', {
      _register: 'addMessageTemplate',
    }),

    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.messageTemplate.body === html)
        return;
      this.messageTemplate.body = html;
      //this.onValueUpdated({quill, html, text})
    },
    onLoad() {
      this.loaded = true
    }
    ,
    createMessageTemplate() {
      let $this = this
      this.messageTemplate.active = this.activeEvent;
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.messageTemplate)
            .then(
                (id) => {

                  $this.messageTemplateId = id
                  $this.$emit('submitted', {status: true, server: id, id})

                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
      })
    },

    validate(field) {
      return this.$v.messageTemplate[field] != null && this.$v.messageTemplate[field].$error === true
    },
    insertPlaceholder(placeholder) {
      const quill = this.$refs.quillEditor.quill;
      const range = quill.getSelection(true);

      if (range) {
        quill.insertText(range.index, placeholder);
        quill.setSelection(range.index + placeholder.length);
      } else {
        quill.insertText(0, placeholder);
        quill.setSelection(placeholder.length);
      }
    }
    ,
    submit() {
      return this.createMessageTemplate()
    },
    copyCredentials(messageTemplate) {
      this.messageTemplate.hostawayId = messageTemplate.hostawayId
      this.messageTemplate.hostawaySecret = messageTemplate.hostawaySecret
    }

    , onBuildingsRetrieve(data) {
      this.buildings = data["Buildings"];
    },
  },

}
</script>
<style scoped>

.dark-layout #realEstate-description {
  background-color: rgba(255, 255, 255, 0.66);
  color: #000000;
}

.quill-container #realEstate-description {

  min-height: 400px;
}
</style>


