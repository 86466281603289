<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Id") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Message Templates') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"

                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onMessageTemplateSelect(0)">
                  <span class="text-nowrap">{{ msg('New Template') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="messageTemplate-table"
            striped
            hover
            responsive
            v-bind:items="messageTemplates"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #cell(active)="data">
            <b-badge v-bind:variant="data.value!=null && data.value?'success':'danger'">
              {{ data.value != null && data.value ? msg('Yes') : msg('No') }}
            </b-badge>
          </template>
          <template #cell(minutes)="data">
            <span>
              {{ data.value }} {{ msg('Minutes') }}
            </span>
          </template>
          <template #cell(propertyNames)="data">
            <b-button variant="primary" id="property-tooltip" style="font-weight: bold;margin-right: 2px">
              {{ msg('Properties') }}
            </b-button>
            <b-tooltip target="property-tooltip" triggers="click" placement="right">
              <div v-for="property in data.value" :key="property">
                {{ property }}
              </div>
            </b-tooltip>
          </template>
          <template #cell(messageChannel)="data">
            <span style="font-weight: bold">
              {{ data.value }}
            </span>
          </template>
          <template #cell(messageEvent)="data">
            <span style="font-weight: bold">
              {{ data.value.replace(/_/g, ' ') }}
            </span>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.messageTemplate" size="xl" ref="messageTemplate-modal" id="messageTemplate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <messageTemplate-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitMessageTemplate">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal no-fade v-model="dialogs.messageTemplateUpdate" size="xl" ref="messageTemplate-modal2"
             id="messageTemplate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <message-template-update-panel ref="realEfdfstatePanel2" @submitted="onSubmittedUpdate"
                                     :value="selectedTemplate"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitMessageTemplateUpdate">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>


  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import MessageTemplatePanel from './messageTemplatePanel'
import MessageTemplateUpdatePanel from "@/views/realEstate/messageTemplate/messageTemplateUpdatePanel.vue";

export default {
  name: 'MessageTemplates',
  components: {
    MessageTemplateUpdatePanel,
    MessageTemplatePanel
  },
  data() {
    return {
      selectedTemplate: null,
      loading: true,
      amount: 10,
      messageTemplates: [],
      fields: [],
      messageTemplateId: null,

      filter: {
        like: {search: ''},
        eq: {type: ""},
        lte: {},
        gte: {},
        in: {}
      },

      sort: {
        by: 'updatedDate',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {messageTemplate: false, messageTemplateUpdate: false},
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),

    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('messageTemplate', ['allStatuse']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatuse)
    },

    allmessageTemplates() {
      return this.messageTemplates
    }
  },
  created() {
    let $this = this;

    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('messageTemplate', ['getAllMessageTemplates', 'copySuccess', 'getExcelTemplateUrl', 'getExcelTemplateUrlClients']),

    uploadFile() {
      let $this = this
      if (this.file == null)
        return;

      let paramMap = {"buid": this.buid}
      this._uploadExcel({file: this.file, params: paramMap}).finally(() => $this.refresh());

    },
    uploadFileClient() {
      let $this = this
      console.log('uploadExcelClient')
      if (this.fileClient == null)
        return;

      let paramMap = {"messageTemplateId": this.messageTemplateId}
      this._uploadExcelClient({fileClient: this.fileClient, params: paramMap}).finally(() => $this.refresh());

    },
    resetFile() {
      this.file = null;
    },
    resetFileClients() {
      this.fileClient = null;
    },
    downloadExcelTemplate() {
      console.log("messageTemplate excel template url :", this.excelUrlMessageTemplates)
      window.open(this.excelUrlMessageTemplates, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },
    downloadExcelTemplateClients() {
      console.log("client excel template url :", this.excelUrlClients)
      window.open(this.excelUrlClients, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },

    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [

        {
          key: 'alias',
          label: $this.msg('Alias'),
          sortable: true,
          active: true,
        },
        {
          key: 'messageEvent',
          label: $this.msg('Event'),
          sortable: true
        },
        {
          key: 'messageChannel',
          label: $this.msg('Channel'),
          sortable: true,
          active: true,
        },
        {
          key: 'propertyNames',
          label: $this.msg('units'),
          sortable: true,
          active: true,
        },
        {
          key: 'active',
          label: $this.msg('Active'),
          sortable: true
        },

        {
          key: 'minutes',
          label: $this.msg('duration'),
          sortable: true
        },

      ]
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllMessageTemplates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: 'desc',
      }).then(this.onMessageTemplatesRetrieve, (error) => $this.error = error)


    },
    onMessageTemplatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.messageTemplates = data["MessageTemplates"];
      this.loading = false;
    },
    onMessageTemplateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.messageTemplate = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(messageTemplate) {
      console.log("messageTemplate im here", messageTemplate)
      this.selectedTemplate = messageTemplate;
      this.dialogs.messageTemplateUpdate = true;
    },
    submitMessageTemplate() {
      this.$root.$emit('crm::submitMessageTemplate')
    },
    submitMessageTemplateUpdate() {
      this.$root.$emit('crm::submitMessageTemplateUpdate')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.messageTemplate = false;
    },
    onSubmittedUpdate(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.messageTemplateUpdate = false;
    },
    getBadgeVariant(count) {
      if (count === 1) {
        return 'warning'; // Green
      } else if (count >= 2) {
        return 'danger'; // Orange
      }
      // Default to primary if count is 0 or undefined
      return 'success';
    },
    getCountOfOpenInvoices(invoices) {
      if (invoices == null) {
        return 0
      }
      let count = 0;
      for (const invoice of invoices) {
        if (invoice.invoiceStatus === 'OPEN') {
          count++;
        }
      }
      return count;
    },
  }
}
</script>

<style>

</style>
